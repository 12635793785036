<template>
    <div>
        <v-btn @click="$emit('openWidget')" color="primary" icon dark >
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-text-field
              class="pt-5 pb-5"
              chips
              clearable
              hide-details
              hide-selected
              prepend-icon="mdi-magnify"
              label="Search"
              v-model="search"
              solo
            />

            <div>
              <v-data-table
                :search="search"
                :headers="[{
                  text: 'Descrição',
                  value: 'description'
                },
                {
                  text: 'Tipo de Gráfico',
                  value: 'typeDashboard'
                },
                {
                  text: '',
                  value: 'actions'
                }
                ]"
                :items="itemsWidgets"
                class="elevation-1"
                disable-sort
                hide-default-footer
              >
              <template v-slot:item.actions="{ item }" v-if="isReadonly">
            <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondary" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu - {{ item }}</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item link>
                <v-list-item-icon>
                    <v-icon color="secondary" v-text="'mdi-code-json'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="'Editar'" @click="updateWidget(item)"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item link>
                <v-list-item-icon>
                    <v-icon
                    color="secondary"
                    v-text="'mdi-trash-can-outline'"
                    ></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="'Delete Widget'" @click="deleteWidget(item.id)"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-menu>
        </template>

        <template slot="no-data">
            <v-alert color="orange" class="mt-4" type="error">
                Esse dashboard ainda não possui widgets :(
            </v-alert>
        </template>
              </v-data-table>
            </div>
    </div>
</template>

<script>
import http from '../../../../http/axiosConfig';
export default {
    name: 'widgetTable',
    props: ['itemsWidgets', 'openWidget', 'isReadonly'],
    data(){
        return {
            //openWidget: false
            search: ''
        }
    },

    methods: {
        async deleteWidget(id){
            await http.delete('Entity/Widget', id);
            this.$emit('delete', id);
        },

        updateWidget(item){
            this.$emit('update', item)
        }
    }
}
</script>

<style>

</style>