<template>
    <div>
        <v-text-field
            outlined
            class="mb-5"
            append-icon="mdi-find-replace"
            label="Pesquisar"
            single-line
            hide-details
            v-model="search"
        ></v-text-field>
        
        <v-col class="text-right">
            <v-btn color="secondary" @click="openDashboardDialog = true"> Nova Dashboard </v-btn>
        </v-col>

        <v-data-table
            :headers="headers"
            hide-default-footer
            class="elevation-1"
            :search="search"
            :items="items"
        >
        <template v-slot:item.actions="{ item }">
            <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondary" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu - {{ item }}</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item link>
                <v-list-item-icon>
                    <v-icon color="secondary" v-text="'mdi-open-in-new'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="viewDialog(item)">
                    <v-list-item-title v-text="'Visualizar'"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="updateDialog(item)">
                <v-list-item-icon>
                    <v-icon color="secondary" v-text="'mdi-code-json'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="'Editar'"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item link>
                <v-list-item-icon>
                    <v-icon
                    color="secondary"
                    v-text="'mdi-trash-can-outline'"
                    ></v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="deleteDashboard(item)">
                    <v-list-item-title v-text="'Delete Dashboard'"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-menu>
        </template>
        
        <template slot="no-data">
            <v-alert color="orange" class="mt-4" type="error">
                Desculpe, você não possui Dashboards! :(
            </v-alert>
        </template>
        
        </v-data-table>
        
        <v-pagination
            @input="paginationChangeHandler"
            v-model="pagination.page"
            :length="pagination.pages"
            circle
        ></v-pagination>

        <show-new-dashboard
            :open="openDashboardDialog"
            :tableViews="tableViews"
            @close="openDashboardDialog = false"
            @save="getDashboards(), 
                openDashboardDialog = false, 
                notifySuccess('Dashboard cadastrado com sucesso.')"
        ></show-new-dashboard>

        <show-view-dashboard
            :open="openViewDialog"
            :item="itemDash"
            :tableViews="tableViews"
            @close="openViewDialog = false"
        >
        </show-view-dashboard>

        <showEditDashboard
            :open="openViewUpdate"
            :item="itemDash"
            :tableViews="tableViews"
            @close="openViewUpdate = false"
            @save="getDashboards(), 
                openViewUpdate = false, 
                notifySuccess('Dashboard editado com sucesso.')"
        >
        </showEditDashboard>


    </div>
</template>

<script>
import notifyConfigMixin from '@/mixins/notifyConfigMixin';
import viewsMainMixin from '../../../mixins/tables/Views/viewsMainMixin'
import showNewDashboard from './dialogs/showNewDashboard.vue'
import showViewDashboard from '@/components/tables/Dashboards/dialogs/showViewDashboard'
import showEditDashboard from '@/components/tables/Dashboards/dialogs/showEditDashboard'
import { HEAD_TABLE } from './handleTableConfig'
import http from '../../../http/axiosConfig';
export default {
    mixins: [viewsMainMixin, notifyConfigMixin],
    components: {
        showNewDashboard,
        showViewDashboard,
        showEditDashboard
    },
    data(){
        return {
            itemDash: null,
            openViewUpdate: false,
            openViewDialog: false,
            openDashboardDialog: false,
            headers: HEAD_TABLE,
            search: '',
            url: 'Entity/Dashboard',
            items: [],
            pagination:{
                page: 1,
                pages: 0
            }
        }
    },

    methods: {
        async getDashboards(){
            let response = await http.get(`${this.url}?page=${this.pagination.page}&limit=30`)
            this.items = response.data.data
            this.pagination.page = response.data.page
            this.pagination.pages = response.data.pages
        },

        viewDialog(item){
            this.openViewDialog = true
            this.itemDash = item
        },

        updateDialog(item){
            this.openViewUpdate = true
            this.itemDash = item
        },

        async deleteDashboard(item){
            if(await this.$isConfirmeDelete('Tem certeza que deseja excluir esse item ')){

                if(item.widgets?.length > 0){
                    item.widgets.map(async resp => {
                        http.delete('Entity/Widget', resp)
                    });
                }

                await http.delete(this.url, item.id)
                this.notifySuccess("Dashboard deletado com sucesso.");
                this.getDashboards()
                return;
            }
        },

        paginationChangeHandler(value){
            this.pagination.page = value
            this.getDashboards()
        }
    },

    mounted(){
        this.$nextTick(this.getViews);
        this.getDashboards()
    },


}
</script>