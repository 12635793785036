<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <dashboard-table>
        </dashboard-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dashboardTable from '@/components/tables/Dashboards/dashboardTable'
export default {
    name: 'DashbardPage',
    components: {
        dashboardTable
    }
}
</script>