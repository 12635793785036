<template>
  <v-dialog
    width="60%"
    scrollable
    transition="dialog-bottom-transition"
    v-model="value"
  >

  <v-card height="100%">
    <div class="mx-3 mb-3">
        <v-card-actions>
            <v-card-title>Nova Dashboard</v-card-title>
            <v-spacer></v-spacer>
            <v-btn text fab color="red">
                <v-icon large @click="close()">mdi-close</v-icon>
            </v-btn>
            
        </v-card-actions>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="postDashboard()">
            Aplicar
          </v-btn>
        </v-card-actions>

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="name"
              outlined
              v-model="newDashboard.name"
            ></v-text-field>


            <v-text-field
              label="descrption"
              outlined
              v-model="newDashboard.description"
            ></v-text-field>

            <v-text-field
              label="Ordem"
              outlined
              v-model="newDashboard.ordem"
              type="number"
            ></v-text-field>

            <v-text-field
              label="mdi"
              outlined
              v-model="newDashboard.mdi"
            ></v-text-field>


            <widget-table
            :isReadonly="true"
            :itemsWidgets="itemsWidgets"
            @openWidget="openWidget = true"
            @update="updateWidget"
            @delete="deleteWidget"
            >
            </widget-table>

          </v-col>
        </v-row>
    </div>
  </v-card>

  <widget-dialog
    :open="openWidget"
    @close="closeWidgetDialog"
    @newWidget="insertWidget"
    :views="tableViews"
    :item="itemWidget"
  >
  </widget-dialog>
  
  </v-dialog>
</template>

<script>
import notifyConfigMixin from '@/mixins/notifyConfigMixin';
import http from '../../../../http/axiosConfig';
import widgetDialog from '../widget/widgetDialog.vue';
import WidgetTable from '../widget/widgetTable.vue';
export default {
  components: { widgetDialog, WidgetTable },
    mixins: [notifyConfigMixin],
    name: 'openDashboardDialog',
    props: ["open", "tableViews", "postViewRun"],
    data(){
        return {
            value: false,
            newDashboard: {
                name: '',
                description: '',
                ordem: 0,
                mdi: '',
                widgets: []
            },
            itemsWidgets: [],
            //url: 'Entity/Dashboard',
            openWidget: false,
            itemWidget: null
        }
    },

    watch: {
        open(value){
            this.value = value
        },

        async ['newDashboard.widgets'](value){

          let ids = value.map((resp) => {
            return resp;
          }).join(',');

          if(ids === ''){
            this.itemsWidgets = []
            return;
          }

          let req = await http.get(`Entity/Widget/search-fields?ids=${ids}`)
          this.itemsWidgets = req.data.data
        }

    },

    methods: {

      close(){

        if(this.newDashboard.widgets.length > 0){
          this.newDashboard.widgets.map(async resp => {
            await http.delete('Entity/Widget', resp)
          })
        }
        this.$emit('close')
      },

      async insertWidget(obj){
        let req
          if(obj.id){
            req = await http.put('Entity/Widget', obj);

            let ids = this.newDashboard.widgets.map((resp) => {
              return resp;
            }).join(',');


            let data = await http.get(`Entity/Widget/search-fields?ids=${ids}`)
            this.itemsWidgets = data.data.data

          } else {
            req = await http.post('Entity/Widget', obj);
            this.newDashboard.widgets.push(req.data.data.id)
          }

          this.itemWidget = null;
          this.openWidget = false
      },



        async postDashboard(){

          if(this.newDashboard.name == '' || this.newDashboard.description == ''){
           this.notifyError('Erro informe nome e descrição');
           return
          }

            await http.post('Entity/Dashboard', this.newDashboard);
            this.$emit("save")
        },

        deleteWidget(id){
          //this.newDashboard.widgets.pop(id)
          this.newDashboard.widgets = this.newDashboard.widgets.filter(resp => resp !== id)
        },

        updateWidget(item){
          this.itemWidget = item
          this.openWidget = true
        },

        closeWidgetDialog(){
          this.openWidget = false
          this.itemWidget = null
        }
    },

    created() {
    },
}
</script>
