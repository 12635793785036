var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"color":"primary","icon":"","dark":""},on:{"click":function($event){return _vm.$emit('openWidget')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-text-field',{staticClass:"pt-5 pb-5",attrs:{"chips":"","clearable":"","hide-details":"","hide-selected":"","prepend-icon":"mdi-magnify","label":"Search","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":[{
              text: 'Descrição',
              value: 'description'
            },
            {
              text: 'Tipo de Gráfico',
              value: 'typeDashboard'
            },
            {
              text: '',
              value: 'actions'
            }
            ],"items":_vm.itemsWidgets,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([(_vm.isReadonly)?{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu - "+_vm._s(item))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('mdi-code-json')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Editar')},on:{"click":function($event){return _vm.updateWidget(item)}}})],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('mdi-trash-can-outline')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Delete Widget')},on:{"click":function($event){return _vm.deleteWidget(item.id)}}})],1)],1)],1)],1)]}}:null],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-4",attrs:{"color":"orange","type":"error"}},[_vm._v(" Esse dashboard ainda não possui widgets :( ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }