<template>
  <v-dialog
    width="60%"
    scrollable
    transition="dialog-bottom-transition"
    v-model="value"
  >

  <v-card height="100%">
    <div class="mx-3 mb-3">
        <v-card-actions>
            <v-card-title>
             Visualizar Dashboard<span class="font-italic">
              (Somente Leitura)
            </span></v-card-title>
            <v-spacer></v-spacer>
            <v-btn text fab color="red">
                <v-icon large @click="$emit('close')">mdi-close</v-icon>
            </v-btn>
            
        </v-card-actions>
        <!--
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="runDashboard()">
            Preview Dashboard
          </v-btn>
        </v-card-actions>-->

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="name"
              outlined
              v-model="item.name"
              :readonly="true"
            ></v-text-field>


            <v-text-field
              label="Ordem"
              outlined
              v-model="item.ordem"
              :readonly="true"
              type="number"
            ></v-text-field>

            <v-text-field
              label="mdi"
              outlined
              v-model="item.mdi"
              :readonly="true"
            ></v-text-field>


            <v-text-field
              label="descrption"
              outlined
              v-model="item.description"
              :readonly="true"
            ></v-text-field>


            <widget-table
            :isReadonly="false"
            :itemsWidgets="itemsWidgets"
            @openWidget="openWidget = true"
            @update="updateWidget"
            @delete="deleteWidget"
            :key="componentKey"
            >
            </widget-table>

          </v-col>

        </v-row>
    </div>
  </v-card>
  
  </v-dialog>
</template>

<script>
import WidgetTable from '../widget/widgetTable.vue';
import Chart from 'chart.js'
import http from '../../../../http/axiosConfig';
export default {
    components: { WidgetTable },
    props: ["open", "item", "tableViews"],
    data(){
        return {
            value: false,
            ctx: null,
            isVisible: false,
            propertiesList: [],
            itemsWidgets: []
        }
    },

    methods: {
        runDashboard(){
            new Chart(this.ctx, {
                type: this.item.chartType,
                
                data: {
                    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                    datasets: [{
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 205, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(201, 203, 207, 0.2)'
                        ],
                        borderWidth: 1
                    }]
                },
                
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            })
        }
    },

    watch: {
        async open(value){
            this.value = value;
            /*
            console.log('ITEM ', this.item)
            setTimeout(() => {
                const canvas = this.$refs.myChart
                this.ctx = canvas
            }, 1000)*/

            console.log('ITEM >>> ', this.item)
            
            let ids = this.item.widgets.map((resp) => {
              return resp;
            }).join(',');

            if(ids === ''){
              this.itemsWidgets = []
              return;
            }

            let req = await http.get(`Entity/Widget/search-fields?ids=${ids}`)
            this.itemsWidgets = req.data.data
        }
    },

    created() {
    },
}
</script>

<style>

</style>