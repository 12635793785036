var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"mb-5",attrs:{"outlined":"","append-icon":"mdi-find-replace","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.openDashboardDialog = true}}},[_vm._v(" Nova Dashboard ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-footer":"","search":_vm.search,"items":_vm.items},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu - "+_vm._s(item))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('mdi-open-in-new')}})],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.viewDialog(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Visualizar')}})],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.updateDialog(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('mdi-code-json')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Editar')}})],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('mdi-trash-can-outline')}})],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.deleteDashboard(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Delete Dashboard')}})],1)],1)],1)],1)]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-4",attrs:{"color":"orange","type":"error"}},[_vm._v(" Desculpe, você não possui Dashboards! :( ")])],1)],2),_c('v-pagination',{attrs:{"length":_vm.pagination.pages,"circle":""},on:{"input":_vm.paginationChangeHandler},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}),_c('show-new-dashboard',{attrs:{"open":_vm.openDashboardDialog,"tableViews":_vm.tableViews},on:{"close":function($event){_vm.openDashboardDialog = false},"save":function($event){_vm.getDashboards(), 
            _vm.openDashboardDialog = false, 
            _vm.notifySuccess('Dashboard cadastrado com sucesso.')}}}),_c('show-view-dashboard',{attrs:{"open":_vm.openViewDialog,"item":_vm.itemDash,"tableViews":_vm.tableViews},on:{"close":function($event){_vm.openViewDialog = false}}}),_c('showEditDashboard',{attrs:{"open":_vm.openViewUpdate,"item":_vm.itemDash,"tableViews":_vm.tableViews},on:{"close":function($event){_vm.openViewUpdate = false},"save":function($event){_vm.getDashboards(), 
            _vm.openViewUpdate = false, 
            _vm.notifySuccess('Dashboard editado com sucesso.')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }