<template>
  <v-dialog
    width="40%"
    scrollable
    transition="dialog-bottom-transition"
    v-model="value"
  >

    <v-card height="100%">
        <div class="mx-3 mb-3">
            
            <v-card-actions>
                <v-card-title>Novo Widget</v-card-title>
                <v-spacer></v-spacer>
                <v-btn text fab color="red">
                    <v-icon large @click="close">mdi-close</v-icon>
                </v-btn>
            
            </v-card-actions>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="sendWidget">
                    Aplicar
                </v-btn>
            </v-card-actions>
            
            <v-row>
                <v-col cols="12">
                    <v-text-field
                    label="descrption"
                    outlined
                    v-model="newWidget.description"
                    ></v-text-field>
                    
                    <v-combobox
                    label="chart types"
                    outlined
                    v-model="newWidget.typeDashboard"
                    :items="chartTypes"
                    ></v-combobox>
                    
                    <v-autocomplete
                    label="View"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="views"
                    v-model="newWidget.viewId"
                    ></v-autocomplete>
                </v-col>

            </v-row>
        </div>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
    props: ['open', 'views', 'item'],
    name: 'widgetDialog',
    data(){
        return {
            value: false,
            chartTypes: [ "chart", "grid", "card" ],
            newWidget: {
                description: this.item?.description || '',
                typeDashboard: this.item?.typeDashboard || '',
                viewId: this.item?.viewId || ''
            }
        }
    },

    watch: {
        open(value){
            this.value = value;
            
            if(this.item?.id){
                this.newWidget.id = this.item?.id || ''
                this.newWidget.description = this.item?.description || ''
                this.newWidget.typeDashboard = this.item?.typeDashboard || ''
                this.newWidget.viewId = this.item?.viewId || ''
            } else {
                this.newWidget = {}
            }
        }
    },

    methods: {
        sendWidget(){
            this.$emit('newWidget', this.newWidget);
        },

        close(){
            this.$emit('close')
        }
    }
}
</script>

<style>

</style>