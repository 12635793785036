<template>
  <v-dialog
    width="60%"
    scrollable
    transition="dialog-bottom-transition"
    v-model="value"
  >

  <v-card height="100%">
    <div class="mx-3 mb-3">
        <v-card-actions>
            <v-card-title>Editar Dashboard</v-card-title>
            <v-spacer></v-spacer>
            <v-btn text fab color="red">
                <v-icon large @click="close">mdi-close</v-icon>
            </v-btn>
            
        </v-card-actions>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="updateDashboard()">
            Aplicar
          </v-btn>
        </v-card-actions>

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="name"
              outlined
              v-model="newDashboard.name"
              :value="newDashboard.name"
            ></v-text-field>

            <v-text-field
              label="descrption"
              outlined
              v-model="newDashboard.description"
              :value="newDashboard.description"
            ></v-text-field>

            <v-text-field
              label="Ordem"
              outlined
              v-model="newDashboard.ordem"
              :value="newDashboard.ordem"
              type="number"
            ></v-text-field>

            <v-text-field
              label="mdi"
              outlined
              v-model="newDashboard.mdi"
              :value="newDashboard.mdi"
            ></v-text-field>

            <widget-table
            :isReadonly="true"
            :itemsWidgets="itemsWidgets"
            @openWidget="openWidget = true"
            @update="updateWidget"
            @delete="deleteWidget"
            >
            </widget-table>

          </v-col>
        </v-row>
    </div>
  </v-card>

  <widget-dialog
    :open="openWidget"
    :item="itemWidget"
    @close="closeWidgetDialog"
    @newWidget="insertWidget"
    :views="tableViews"
  >
  </widget-dialog>
  
  
  </v-dialog>
</template>

<script>
import notifyConfigMixin from '@/mixins/notifyConfigMixin';
import http from '../../../../http/axiosConfig';
import widgetDialog from '../widget/widgetDialog.vue';
import WidgetTable from '../widget/widgetTable.vue';
export default {
    mixins: [notifyConfigMixin],
    props: ["open", "item", "tableViews"],
    components: { WidgetTable, widgetDialog },
    data(){
        return {
            openWidget: false,
            value: false,
            itemsWidgets: [],
            newDashboard: {
                name: this.item?.name,
                description: this.item?.description,
                mdi: this.item?.mdi,
                widgets: this.item?.widgets,
                ordem: this.item?.ordem,
                id: this.item?.id
            },
            url: 'Entity/Dashboard',
            properties: [],
            propertiesList: [],
            itemWidget: null,
            widgetsAux: []
        }
    },

    watch: {
        async open(value){
            this.value = value
            this.newDashboard.id = this.item?.id
            this.newDashboard.name = this.item?.name
            this.newDashboard.description = this.item?.description
            this.newDashboard.mdi = this.item?.mdi
            this.newDashboard.ordem = this.item?.ordem
            this.newDashboard.widgets = this.item?.widgets || []

            let ids = this.newDashboard.widgets.map(resp => {
              return resp;
            }).join(',');
            
            if(ids === ''){
              this.itemsWidgets = []
              return;
            }


            let req = await http.get(`Entity/Widget/search-fields?ids=${ids}`)
            this.itemsWidgets = req.data.data
        },
        
        async ['newDashboard.widgets'](value){

          let ids = value.map((resp) => {
            return resp;
          }).join(',');

          if(ids === ''){
            this.itemsWidgets = []
            return;
          }
          
          let req = await http.get(`Entity/Widget/search-fields?ids=${ids}`)
          this.itemsWidgets = req.data.data
        }

    },

    methods: {
        async updateDashboard(){
          
            if(this.newDashboard.name == '' || this.newDashboard.description == ''){
              this.notifyError('Erro informe nome e descrição');
              return
            }

            await http.put(this.url, this.newDashboard);
            this.$emit('save')
            this.$emit('close')
        },
        
        close(){

          if(this.widgetsAux.length > 0){
            this.widgetsAux.map(async resp => {
              await http.delete('Entity/Widget', resp)
            })
          }

          this.$emit('close')
        },

        async insertWidget(obj){

          let req
          if(obj.id){
            req = await http.put('Entity/Widget', obj);

            let ids = this.newDashboard.widgets.map((resp) => {
              return resp;
            }).join(',');


            let data = await http.get(`Entity/Widget/search-fields?ids=${ids}`)
            this.itemsWidgets = data.data.data

          } else {
            req = await http.post('Entity/Widget', obj);
            this.newDashboard.widgets.push(req.data.data.id)
            this.widgetsAux.push(req.data.data.id)
          }

          this.itemWidget = null;
          this.openWidget = false
        },

        deleteWidget(id){
          //this.newDashboard.widgets.pop(id)
          this.newDashboard.widgets = this.newDashboard.widgets.filter(resp => resp !== id)
        },

        updateWidget(item){
          this.openWidget = true
          this.itemWidget = item
        },

        closeWidgetDialog(){
          this.itemWidget = null
          this.openWidget = false
        }
    }
}
</script>

<style>

</style>